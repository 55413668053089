@charset "UTF-8";

@font-face {
  font-family: "star-trek-reloaded";
  src:url("./static/star-trek-reloaded.eot");
  src:url("./static/star-trek-reloaded.eot?#iefix") format("embedded-opentype"),
    url("./static/star-trek-reloaded.woff") format("woff"),
    url("./static/star-trek-reloaded.ttf") format("truetype"),
    url("./static/star-trek-reloaded.svg#star-trek-reloaded") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "star-trek-reloaded" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="str-"]:before,
[class*=" str-"]:before {
  font-family: "star-trek-reloaded" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.str-armchair-chair-streamline:before {
  content: "\61";
}
.str-arrow-streamline-target:before {
  content: "\62";
}
.str-backpack-streamline-trekking:before {
  content: "\63";
}
.str-bag-shopping-streamline:before {
  content: "\64";
}
.str-barbecue-eat-food-streamline:before {
  content: "\65";
}
.str-barista-coffee-espresso-streamline:before {
  content: "\66";
}
.str-bomb-bug:before {
  content: "\67";
}
.str-book-dowload-streamline:before {
  content: "\68";
}
.str-book-read-streamline:before {
  content: "\69";
}
.str-browser-streamline-window:before {
  content: "\6a";
}
.str-brush-paint-streamline:before {
  content: "\6b";
}
.str-bubble-comment-streamline-talk:before {
  content: "\6c";
}
.str-bubble-love-streamline-talk:before {
  content: "\6d";
}
.str-caddie-shop-shopping-streamline:before {
  content: "\6e";
}
.str-caddie-shopping-streamline:before {
  content: "\6f";
}
.str-camera-photo-polaroid-streamline:before {
  content: "\70";
}
.str-camera-photo-streamline:before {
  content: "\71";
}
.str-camera-streamline-video:before {
  content: "\72";
}
.str-chaplin-hat-movie-streamline:before {
  content: "\73";
}
.str-chef-food-restaurant-streamline:before {
  content: "\74";
}
.str-clock-streamline-time:before {
  content: "\75";
}
.str-cocktail-mojito-streamline:before {
  content: "\76";
}
.str-coffee-streamline:before {
  content: "\77";
}
.str-computer-imac:before {
  content: "\78";
}
.str-computer-imac-2:before {
  content: "\79";
}
.str-computer-macintosh-vintage:before {
  content: "\7a";
}
.str-computer-network-streamline:before {
  content: "\41";
}
.str-computer-streamline:before {
  content: "\42";
}
.str-cook-pan-pot-streamline:before {
  content: "\43";
}
.str-crop-streamline:before {
  content: "\44";
}
.str-crown-king-streamline:before {
  content: "\45";
}
.str-danger-death-delete-destroy-skull-stream:before {
  content: "\46";
}
.str-dashboard-speed-streamline:before {
  content: "\47";
}
.str-database-streamline:before {
  content: "\48";
}
.str-delete-garbage-streamline:before {
  content: "\49";
}
.str-design-graphic-tablet-streamline-tablet:before {
  content: "\4a";
}
.str-design-pencil-rule-streamline:before {
  content: "\4b";
}
.str-diving-leisure-sea-sport-streamline:before {
  content: "\4c";
}
.str-drug-medecine-streamline-syringue:before {
  content: "\4d";
}
.str-earth-globe-streamline:before {
  content: "\4e";
}
.str-eat-food-fork-knife-streamline:before {
  content: "\4f";
}
.str-eat-food-hotdog-streamline:before {
  content: "\50";
}
.str-edit-modify-streamline:before {
  content: "\51";
}
.str-email-mail-streamline:before {
  content: "\52";
}
.str-envellope-mail-streamline:before {
  content: "\53";
}
.str-eye-dropper-streamline:before {
  content: "\54";
}
.str-factory-lift-streamline-warehouse:before {
  content: "\55";
}
.str-first-aid-medecine-shield-streamline:before {
  content: "\56";
}
.str-food-ice-cream-streamline:before {
  content: "\57";
}
.str-frame-picture-streamline:before {
  content: "\58";
}
.str-grid-lines-streamline:before {
  content: "\59";
}
.str-handle-streamline-vector:before {
  content: "\5a";
}
.str-happy-smiley-streamline:before {
  content: "\30";
}
.str-headset-sound-streamline:before {
  content: "\31";
}
.str-home-house-streamline:before {
  content: "\32";
}
.str-ibook-laptop:before {
  content: "\33";
}
.str-ink-pen-streamline:before {
  content: "\34";
}
.str-ipad-streamline:before {
  content: "\35";
}
.str-iphone-streamline:before {
  content: "\36";
}
.str-ipod-mini-music-streamline:before {
  content: "\37";
}
.str-ipod-music-streamline:before {
  content: "\38";
}
.str-ipod-streamline:before {
  content: "\39";
}
.str-japan-streamline-tea:before {
  content: "\21";
}
.str-laptop-macbook-streamline:before {
  content: "\22";
}
.str-like-love-streamline:before {
  content: "\23";
}
.str-link-streamline:before {
  content: "\24";
}
.str-lock-locker-streamline:before {
  content: "\25";
}
.str-locker-streamline-unlock:before {
  content: "\26";
}
.str-macintosh:before {
  content: "\27";
}
.str-magic-magic-wand-streamline:before {
  content: "\28";
}
.str-magnet-streamline:before {
  content: "\29";
}
.str-map-pin-streamline:before {
  content: "\2a";
}
.str-map-streamline-user:before {
  content: "\2b";
}
.str-micro-record-streamline:before {
  content: "\2c";
}
.str-monocle-mustache-streamline:before {
  content: "\2d";
}
.str-music-note-streamline:before {
  content: "\2e";
}
.str-music-speaker-streamline:before {
  content: "\2f";
}
.str-notebook-streamline:before {
  content: "\3a";
}
.str-paint-bucket-streamline:before {
  content: "\3b";
}
.str-painting-pallet-streamline:before {
  content: "\3c";
}
.str-painting-roll-streamline:before {
  content: "\3d";
}
.str-pen-streamline:before {
  content: "\3e";
}
.str-pen-streamline-1:before {
  content: "\3f";
}
.str-pen-streamline-2:before {
  content: "\40";
}
.str-pen-streamline-3:before {
  content: "\5b";
}
.str-photo-pictures-streamline:before {
  content: "\5d";
}
.str-picture-streamline:before {
  content: "\5e";
}
.str-picture-streamline-1:before {
  content: "\5f";
}
.str-receipt-shopping-streamline:before {
  content: "\60";
}
.str-remote-control-streamline:before {
  content: "\7b";
}
.str-settings-streamline:before {
  content: "\7c";
}
.str-settings-streamline-1:before {
  content: "\7d";
}
.str-settings-streamline-2:before {
  content: "\7e";
}
.str-shoes-snickers-streamline:before {
  content: "\5c";
}
.str-speech-streamline-talk-user:before {
  content: "\e000";
}
.str-stamp-streamline:before {
  content: "\e001";
}
.str-streamline-suitcase-travel:before {
  content: "\e002";
}
.str-streamline-sync:before {
  content: "\e003";
}
.str-streamline-umbrella-weather:before {
  content: "\e004";
}
.str-man-people-streamline-user:before {
  content: "\e005";
}
