.container {
  width: 100%;
  height: inherit;
}
.center_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.page {
  padding-right: 100px;
}
.content {
  background-color: #F5F9FC;
  padding: 25px;
  height: inherit;
}
.inner_container_980{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
}
.right_menu {
  position: fixed;
  right: 0;
  width: 100px;
  height: 100%;
  background-color: #343E4E;
  text-align: center;
}
.rm_top_icon {
  width: 31px;
  padding: 18px 0;
}
/* .rm_icon {} */
.rm_item {
  display: block;
}
.right_menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
/* .right_menu ul li{} */
.right_menu ul li a{
  display: block;
  width: inherit;
  height: 100px;
  text-decoration: none;
  font-size: 11px;
  color: #8E98AA;
}
.right_menu ul li a span[class^="str-"] {
  display: block;
  font-size: 30px;
  padding-top: 22px;
  padding-bottom: 5px;
}
.deck_builder_icon {
  transform: rotate(-90deg);
  padding-top: 35px !important;
  padding-bottom: 0px !important;
  margin-bottom: -7px !important;
  margin-left: -30px !important;
}
.right_menu ul li a:hover {
  color: #FFFFFF;
  background-color: #0070C5;
}
.right_menu ul li a.active {
  color: #FFFFFF;
  background-color: #0070C5;
}
.header {
  background-color: #FFFFFF;
  padding: 25px;
  border-bottom: #DAE1EA 1px solid;
}
.header_logo {
  width: 190px;
}
.sub_header {
  background-color: #FFFFFF;
  border-bottom: #F3F5F8 1px solid;
  text-align: center;
}
.footer {
  border-top: #E1E8EF 1px solid;
  background-color: #F5F9FC;
  text-align: center;
  padding: 20px;
}
.footer_icon {
  width: 22px;
}
.footer p {
  display: block;
  color: #9DA3AF;
  font-size: 8px;
  margin: 13px 0 0 0;
}
.match_block {
  padding-bottom: 25px;
}
.match_date{
  text-align: center;
}
.match_date p{
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.match_round{
  text-align: center;
}
.match_round p{
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.match {
  display: block;
  height: 70px;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-top: 8px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
}
.match:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-out;
}
.match_home, .match_away {
  width: 50%;
  height: inherit;
}
.match_home {
  float: right;
}
.match_away {
  float: left;
}
.player_away p,
.player_home p,
.score_away p,
.score_home p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-size: 32px;
  padding-top: 9px;
}
.player_away {
  display: inline-block;
  float: left;
}
.player_away p {
  margin: 0;
  padding-left: 18px;
}
.score_away {
  display: inline-block;
  float: right;
}
.score_away p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  padding-left: 15px;
  padding-right: 20px;
}
.player_home {
  display: inline-block;
  float: right;
}
.player_home p {
  margin: 0;
  padding-right: 18px;
}
.score_home {
  display: inline-block;
  float: left;
}
.score_home p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  padding-left: 20px;
  padding-right: 15px;
}
.affiliation {
  display: inline-block;
  width: 95px;
  height: inherit;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
div.match_away > div.affiliation {
  float: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
div.match_home > div.affiliation {
  float: right;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.loss p{
  opacity: .4;
}
.federation {
  background-image: url("./static/flag-federation.png");
  background-color: #2BBCEE;
}
.klingon {
  background-image: url("./static/flag-klingon.png");
  background-color: #000000;
}
.romulan {
  background-image: url("./static/flag-romulan.png");
  background-color: #82BC82;
}
.non_aligned {
  background-image: url("./static/flag-non-aligned.png");
  background-color: #C9AB2D;
}
.borg {
  background-image: url("./static/flag-borg.png");
  background-color: #24292B;
}
.cardassian {
  background-image: url("./static/flag-cardassian.png");
  background-color: #6268AF;
}
.bajoran {
  background-image: url("./static/flag-bajoran.png");
  background-color: #964585;
}
.dominion {
  background-image: url("./static/flag-dominion.png");
  background-color: #84620B;
}
.ferengi {
  background-image: url("./static/flag-ferengi.png");
  background-color: #ED7A31;
}
.default {
  background-image: url("./static/flag-default.png");
  background-color: #8E98AA;
}
.sub_header ul {
    list-style-type: none;
   margin: 0;
    padding: 0;
    overflow: hidden;
  text-align: center;
  margin-bottom: -4px;
}
.sub_header ul li {
  display: inline-block;
}
.sub_header ul li a {
    display: block;
  font-size: 11px;
    color: #404858;
    text-align: center;
    padding: 16px 0;
  min-width: 150px;
    text-decoration: none;
  text-transform: uppercase;
  border-bottom: #F5F9FC 4px solid;
  opacity: 0.5;
}
.sub_header ul li a:hover {
  border-bottom: #0070C5 4px solid;
  font-weight: 700;
  opacity: 1;
}
.sub_header ul li a.active {
  border-bottom: #0070C5 4px solid;
  font-weight: 700;
  opacity: 1;
}
.standings {
  height: calc(100vh - 299px);
  min-height: 525px;
}
.standings_header {
  display: block;
  background-color: #A3ABB7;
  border-radius: 5px;
  height: 45px;
}
.standings_header p {
  margin: 0;
  padding-top: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: .75;
}
.std_hdr_player_col {
  display: inline-block;
  height: 30px;
  margin-top: 7px;
  width: 30%;
  border-right: #8E98AA 1px solid;
}
.std_hdr_player_col p {
  padding-left: 20px;
}
.std_hdr_wl_col {
  display: inline-block;
  height: 30px;
  margin-top: 7px;
  width: 34%;
  border-right: #8E98AA 1px solid;
}
.std_hdr_wl_col p {
  display: inline-block;
  width: 25%;
  text-align: center;
}
.std_hdr_pts_col {
  display: inline-block;
  height: 30px;
  margin-top: 7px;
  width: 27%;
  border-right: #8E98AA 1px solid;
}
.std_hdr_pts_col p {
  display: inline-block;
  width: 32%;
  text-align: center;
}
.std_hdr_stk_col {
  display: inline-block;
  height: 30px;
  margin-top: 7px;
  width: 7%;
}
.std_hdr_stk_col p {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.std_player {
  display: block;
  height: 60px;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-top: 8px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
}
.std_player:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-out;
}
.std_player_name {
  display: inline-block;
  width: 22.3%;
  float: left;
  height: 40px;
  margin-top: 10px;
  border-right: #DAE1EA 1px solid;
}
.std_player_name p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  padding-left: 18px;
  padding-top: 4px;
}
.std_player_avatar {
  display: inline-block;
  width: 75px;
  height: inherit;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000000;
  background-image: url("./static/avatar-TNG-20190821.png");
}
div.std_player_avatar {
  float: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.std_player_wl {
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  width: 34.1%;
  padding-left: 3px;
  border-right: #DAE1EA 1px solid;
}
.std_player_wl p {
  display: inline-block;
  width: 25%;
  margin: 0;
    padding-top: 12px;
  font-size: 13px;
  text-align: center;
}
.std_player_pts {
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  width: 26.4%;
    padding-right: 7px;
  border-right: #DAE1EA 1px solid;
}
.std_player_pts p {
  display: inline-block;
  width: 33%;
  margin: 0;
    padding-top: 12px;
  font-size: 13px;
  text-align: center;
}
.std_player_stk {
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  width: 7%;
}
.std_player_stk p {
  display: inline-block;
  width: 100%;
  margin: 0;
    padding-top: 12px;
  font-size: 13px;
  text-align: center;
}
#graham_matuszewski > .std_player_avatar {
  background-image: url("./static/avatar-gmatuszewski-20190821.png") !important;
}
#robert_bozich > .std_player_avatar {
  background-image: url("./static/avatar-rbozich-20190821.png") !important;
}
#rex_feany > .std_player_avatar {
  background-image: url("./static/avatar-rexfeany-20190821.png") !important;
}
#matt_lubner > .std_player_avatar {
  background-image: url("./static/avatar-mlubner-20190821.png") !important;
}
#jon_callan > .std_player_avatar {
  background-image: url("./static/avatar-jcallan-20190821.png") !important;
}
#rob_green > .std_player_avatar {
  background-image: url("./static/avatar-rgreen-20190824.png") !important;
}

@media screen and (max-width: 870px) {
  .header {
    padding: 22px;
  }
  .header_logo {
      width: 142px;
  }
  .page {
      padding-right: 55px;
  }
  .right_menu {
    width: 55px;
  }
  .rm_top_icon {
    width: 22px;
    padding: 18px 0;
  }
  .right_menu ul li a{
    height: 55px;
  }
  .right_menu ul li a span[class^="str-"] {
    display: block;
    font-size: 25px;
    padding-top: 14px;
    padding-bottom: 0px;
  }
  .deck_builder_icon {
      padding-top: 25px !important;
      margin-bottom: 0px !important;
      margin-left: -20px !important;
  }
  .rm_item {
    display: none;
  }
  .match_date p {
    font-size: 11px;
    padding-bottom: 8px;
  }
  .match {
    height: 50px;
  }
  .affiliation {
    width: 65px;
  }
  .player_away p,
  .player_home p,
  .score_away p,
  .score_home p {
      font-size: 25px;
      padding-top: 5px;
  }
  .score_away p{
    padding-right: 15px;
  }
  .score_home p{
    padding-left: 15px;
  }
}
